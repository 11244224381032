<ng-container *ngIf="!isOnFooter2021">
  <div class="flowaccount-landing">
    <div id="site-map-div">
      <div class="site-map-width clearfix">
        <div class="section">
          <!-- 1._product -->
          <div class="site-map-heading">
            {{ 'Navigation.Products' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
                >FlowAccount</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/mobile-pos"
                rel="nofollow noopener"
                target="_blank"
                >MobilePOS</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-line white-color" href="https://flowpayroll.co/" rel="nofollow noopener" target="_blank"
                >Payroll</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-line white-color" href="https://autokey.io/" rel="nofollow noopener" target="_blank"
                >AutoKey</a
              >
            </li>
          </ul>
        </div>
        <div class="section margin-center">
          <!-- 2._function-account -->
          <!-- 2.1 flowaccount -->
          <div class="flowaccount">
            <div class="site-map-heading">FlowAccount</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  [routerLink]="[language | async, 'functions', 'business']"
                  rel="noopener"
                  >{{ 'Sitemap.BusinessFunctions' | translate }}</a
                >
              </li>
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  [routerLink]="[language | async, 'functions', 'accounting']"
                  rel="noopener"
                  >{{ 'Sitemap.AccountingFunctions' | translate }}</a
                >
              </li>
              <li class="sile-map-li">
                <a class="white-color white-line" [routerLink]="[language | async, 'developers']">{{
                  'Navigation.Developer' | translate
                }}</a>
              </li>
            </ul>
          </div>
          <!-- 2.2 flowpayroll -->
          <div class="flowpayroll margin-pc">
            <div class="site-map-heading">Payroll</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a class="white-line" href="https://flowpayroll.co/functions" rel="nofollow noopener" target="_blank">{{
                  'Sitemap.BusinessFunctions' | translate
                }}</a>
              </li>
            </ul>
          </div>
          <!-- 2.2 Autokey -->
          <div class="flowpayroll margin-pc">
            <div class="site-map-heading">AutoKey</div>
            <ul class="site-map-ul">
              <li class="sile-map-li"
                ><a class="white-line" href="https://autokey.io/" rel="nofollow noopener" target="_blank">
                  {{ 'Sitemap.BusinessFunctions' | translate }}</a
                ></li
              >
              <li class="sile-map-li"
                ><a class="white-line" href="https://autokey.io/" rel="nofollow noopener" target="_blank">
                  {{ 'Sitemap.AccountingFunctions' | translate }}</a
                ></li
              >
            </ul>
          </div>
        </div>
        <div class="section-ipad">
          <div class="list-box _function-payroll margin-mb">
            <div class="site-map-heading">Payroll</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  href="https://flowpayroll.co/functions"
                  rel="nofollow noopener"
                  target="_blank"
                  >{{ 'Sitemap.BusinessFunctions' | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div class="list-box _function-payroll margin-mb" style="margin-top: 50px">
            <div class="site-map-heading">AutoKey</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a class="white-color white-line" href="https://autokey.io/" rel="nofollow noopener" target="_blank">{{
                  'Sitemap.BusinessFunctions' | translate
                }}</a>
              </li>
              <li class="sile-map-li"
                ><a class="white-line" href="https://autokey.io/" rel="nofollow noopener" target="_blank">
                  {{ 'Sitemap.AccountingFunctions' | translate }}</a
                ></li
              >
            </ul>
          </div>
        </div>

        <div class="section margin-ipad margin-center">
          <!-- 5._company -->
          <div>
            <div class="site-map-heading">{{ 'Sitemap.Company' | translate }}</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  [routerLink]="[language | async, 'accountingfirm', 'businessowner']"
                  >{{ 'Sitemap.BusinessOwner' | translate }}</a
                >
              </li>
              <li class="sile-map-li">
                <a class="white-color white-line" [routerLink]="[language | async, 'accountingfirm', 'accountant']">{{
                  'Sitemap.Accountant' | translate
                }}</a>
              </li>
            </ul>
          </div>
          <!-- 4._academy -->
          <div class="margin-pc _academy">
            <div class="site-map-heading">
              {{ 'Navigation.Seminar' | translate }}
            </div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a class="white-color white-line" [routerLink]="[language | async, 'seminars']">{{
                  'Navigation.Seminar_SubMenu' | translate
                }}</a>
              </li>
              <li class="sile-map-li">
                <a class="white-color white-line" [routerLink]="[language | async, 'tutorials']">{{
                  'Sitemap.Tutorial' | translate
                }}</a>
              </li>
              <li class="sile-map-li">
                <a class="white-color white-line" [routerLink]="[language | async, 'ultimateguide']">{{
                  'Navigation.Ultimate' | translate
                }}</a>
              </li>
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  href="https://flowaccount.com/faq"
                  rel="nofollow noopener"
                  target="_blank"
                  >{{ 'Navigation.Faq' | translate }}</a
                >
              </li>
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  href="https://flowaccount.com/blog"
                  target="_blank"
                  rel="nofollow noopener"
                  >{{ 'Sitemap.Blog' | translate }}</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="section-mobile margin-ipad">
          <div class="list-box _function-payroll margin-mb">
            <div class="site-map-heading">Payroll</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a
                  class="white-color white-line"
                  href="https://flowpayroll.co/functions"
                  rel="nofollow noopener"
                  target="_blank"
                  >{{ 'Sitemap.BusinessFunctions' | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div class="list-box _function-payroll margin-mb" style="margin-top: 35px">
            <div class="site-map-heading">AutoKey</div>
            <ul class="site-map-ul">
              <li class="sile-map-li">
                <a class="white-color white-line" href="https://autokey.io/" rel="nofollow noopener" target="_blank">{{
                  'Sitemap.BusinessFunctions' | translate
                }}</a>
              </li>
              <li class="sile-map-li"
                ><a class="white-line" href="https://autokey.io/" rel="nofollow noopener" target="_blank">
                  {{ 'Sitemap.AccountingFunctions' | translate }}</a
                ></li
              >
            </ul>
          </div>
        </div>
        <div class="section margin-ipad _academy_view">
          <!-- 4._academy -->
          <div class="site-map-heading">
            {{ 'Navigation.Seminar' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'seminars']">{{
                'Navigation.Seminar_SubMenu' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'tutorials']">{{
                'Sitemap.Tutorial' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'ultimateguide']">{{
                'Navigation.Ultimate' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/faq"
                rel="nofollow noopener"
                target="_blank"
                >{{ 'Navigation.Faq' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/blog"
                target="_blank"
                rel="nofollow noopener"
                >{{ 'Sitemap.Blog' | translate }}</a
              >
            </li>
          </ul>
        </div>
        <div class="section-last margin-ipad">
          <!-- 6._contact -->
          <div class="site-map-heading">{{ 'Navigation.More' | translate }}</div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'about-us']">{{
                'Sitemap.About' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <ng-container *ngIf="!hasContactUsPage">
                <a class="white-color white-line" href="javascript:void(0)" (click)="goToContact()">{{
                  'Navigation.Contact Us' | translate
                }}</a>
              </ng-container>

              <ng-container *ngIf="hasContactUsPage">
                <a class="white-color white-line" [routerLink]="[language | async, 'contact-us']">{{
                  'Navigation.Contact Us' | translate
                }}</a>
              </ng-container>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'jobs']">{{
                'Navigation.Jobs' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/term-of-use" target="_blank">{{
                'Navigation.termOfUse' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/privacy-statement" target="_blank">{{
                'Navigation.Privacy' | translate
              }}</a>
            </li>

            <li class="sile-map-li max-150">
              <a
                class="white-color white-line"
                href="{{ language | async }}/upgrade-to-new-flowaccount"
                target="_blank"
                [innerHTML]="'Navigation.Migration' | translate"
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isOnFooter2021">
  <section class="sitemap-wrapper">
    <div class="container">
      <!-- DESKTOP -->
      <div class="sitemap-desktop">
        <!-- 1 -->
        <div class="sitemap-desktop__column column--1">
          <div class="site-map__heading">
            {{ 'Navigation.Products' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
                >FlowAccount</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/mobile-pos"
                rel="nofollow noopener"
                target="_blank"
                >MobilePOS</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/payroll"
                rel="nofollow noopener"
                target="_blank"
                >Payroll</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/autokey"
                rel="nofollow noopener"
                target="_blank"
                >AutoKey</a
              >
            </li>
          </ul>
        </div>
        <!-- 2 -->
        <div class="sitemap-desktop__column column--2">
          <!-- FlowAccount -->
          <div class="site-map__heading"> FlowAccount </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'functions', 'business']"
                rel="noopener"
                >{{ 'Sitemap.BusinessFunctions' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'functions', 'accounting']"
                rel="noopener"
                >{{ 'Sitemap.AccountingFunctions' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'functions', 'quotation']">
                {{ 'Sitemap.quotation' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'functions', 'receipt']">
                {{ 'Sitemap.receipt' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'developers']">{{
                'Navigation.Developer' | translate
              }}</a>
            </li>
          </ul>
        </div>
        <!-- 3 -->
        <div class="sitemap-desktop__column column--3">
          <div class="site-map__heading">
            {{ 'Navigation.Seminar' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'seminars']">{{
                'Navigation.Seminar_SubMenu' | translate
              }}</a>
            </li>

            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/blog"
                target="_blank"
                rel="nofollow noopener"
                >{{ 'Sitemap.knowledge-accounting' | translate }}</a
              >
            </li>

            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'tutorials']">{{
                'Sitemap.Tutorial' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'ultimateguide']">{{
                'Navigation.Ultimate' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/faq"
                rel="nofollow noopener"
                target="_blank"
                >{{ 'Navigation.Faq' | translate }}</a
              >
            </li>
          </ul>
        </div>
        <!-- 4 -->
        <div class="sitemap-desktop__column column--4">
          <div class="site-map__heading">{{ 'Navigation.About Us' | translate }}</div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'accountingfirm', 'become-our-partner']"
              >
                {{ 'Sitemap.Become-partner' | translate }}
              </a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'about-us']">
                {{ 'Sitemap.About' | translate }}
              </a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'contact-us']">{{
                'Navigation.Contact Us' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'jobs']">{{
                'Navigation.Jobs' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/term-of-use" target="_blank">{{
                'Navigation.termOfUse' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/privacy-statement" target="_blank">{{
                'Navigation.Privacy' | translate
              }}</a>
            </li>

            <li class="sile-map-li max-150">
              <a
                class="white-color white-line"
                href="{{ language | async }}/upgrade-to-new-flowaccount"
                target="_blank"
                [innerHTML]="'Navigation.Migration' | translate"
              ></a>
            </li>
          </ul>
        </div>
        <!-- 5 -->
        <div class="sitemap-desktop__column column--5">
          <div class="site-map__heading">
            <div class="site-map__heading" [routerLink]="[language | async, 'functions', 'calculator-tax-and-profit']">
              <span class="vat-wht-calculation">{{ 'Sitemap.vat-wht-calculation' | translate }}</span>
            </div></div
          >
        </div>
        <!-- 6 -->
        <div class="sitemap-desktop__column column--6">
          <!-- FlowPayroll -->
          <div class="site-map__heading"> Payroll </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-line" href="https://flowpayroll.co/functions" rel="nofollow noopener" target="_blank">{{
                'Sitemap.BusinessFunctions' | translate
              }}</a>
            </li>
          </ul>

          <!-- Auto key -->
          <div class="site-map__heading autokey">AutoKey</div>
          <ul class="site-map-ul">
            <li class="sile-map-li"
              ><a
                class="white-line"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
              >
                {{ 'Sitemap.BusinessFunctions' | translate }}</a
              ></li
            >
            <li class="sile-map-li"
              ><a
                class="white-line"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
              >
                {{ 'Sitemap.AccountingFunctions' | translate }}</a
              ></li
            >
          </ul>
        </div>
        <!-- 7 -->
        <div class="sitemap-desktop__column column--7">
          <div
            class="site-map__heading find-accounting"
            [routerLink]="[language | async, 'accountingfirm', 'businessowner']"
          >
            <span class="find-accounting-firm">{{ 'Sitemap.find-accountant' | translate }}</span>
          </div>
        </div>
        <!-- 8 -->
        <div class="sitemap-desktop__column column--8"> </div>
      </div>

      <!-- TABLET -->
      <div class="sitemap-tablet">
        <div class="sitemap-tablet__column column--1">
          <div class="site-map__heading">
            {{ 'Navigation.Products' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
                >FlowAccount</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/mobile-pos"
                rel="nofollow noopener"
                target="_blank"
                >MobilePOS</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowpayroll.co/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
                >Payroll</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
                >AutoKey</a
              >
            </li>
          </ul>
        </div>
        <div class="sitemap-tablet__column column--2">
          <div class="site-map__heading"> FlowAccount </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'functions', 'business']"
                rel="noopener"
                >{{ 'Sitemap.BusinessFunctions' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'functions', 'accounting']"
                rel="noopener"
                >{{ 'Sitemap.AccountingFunctions' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'functions', 'quotation']">
                {{ 'Sitemap.quotation' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'functions', 'receipt']">
                {{ 'Sitemap.receipt' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'developers']">{{
                'Navigation.Developer' | translate
              }}</a>
            </li>
          </ul></div
        >
        <div class="sitemap-tablet__column column--3">
          <div class="site-map__heading">{{ 'Navigation.About Us' | translate }}</div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'accountingfirm', 'become-our-partner']"
              >
                {{ 'Sitemap.Become-partner' | translate }}
              </a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'about-us']">
                {{ 'Sitemap.About' | translate }}
              </a>
            </li>
            <li class="sile-map-li">
              <ng-container *ngIf="!hasContactUsPage">
                <a class="white-color white-line" href="javascript:void(0)" (click)="goToContact()">{{
                  'Navigation.Contact Us' | translate
                }}</a>
              </ng-container>

              <ng-container *ngIf="hasContactUsPage">
                <a class="white-color white-line" [routerLink]="[language | async, 'contact-us']">{{
                  'Navigation.Contact Us' | translate
                }}</a>
              </ng-container>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'jobs']">{{
                'Navigation.Jobs' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/term-of-use" target="_blank">{{
                'Navigation.termOfUse' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/privacy-statement" target="_blank">{{
                'Navigation.Privacy' | translate
              }}</a>
            </li>

            <li class="sile-map-li max-150">
              <a
                class="white-color white-line"
                href="{{ language | async }}/upgrade-to-new-flowaccount"
                target="_blank"
                [innerHTML]="'Navigation.Migration' | translate"
              ></a>
            </li> </ul
        ></div>
        <div class="sitemap-tablet__column column--4">
          <div
            class="site-map__heading find-accounting"
            [routerLink]="[language | async, 'accountingfirm', 'businessowner']"
          >
            {{ 'Sitemap.find-accountant' | translate }}
          </div>
          <div
            class="site-map__heading vat-wht-calculation"
            [routerLink]="[language | async, 'functions', 'calculator-tax-and-profit']"
          >
            {{ 'Sitemap.vat-wht-calculation' | translate }}
          </div>
        </div>
        <div class="sitemap-tablet__column column--5">
          <!-- FlowPayroll -->
          <div class="site-map__heading"> Payroll </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-line" href="https://flowpayroll.co/functions" rel="nofollow noopener" target="_blank">{{
                'Sitemap.BusinessFunctions' | translate
              }}</a>
            </li>
          </ul>

          <!-- Auto key -->
          <div class="site-map__heading autokey">AutoKey</div>
          <ul class="site-map-ul">
            <li class="sile-map-li"
              ><a
                class="white-line"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
              >
                {{ 'Sitemap.BusinessFunctions' | translate }}</a
              ></li
            >
            <li class="sile-map-li"
              ><a
                class="white-line"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
              >
                {{ 'Sitemap.AccountingFunctions' | translate }}</a
              ></li
            >
          </ul>
        </div>
        <div class="sitemap-tablet__column column--6">
          <div class="site-map__heading">
            {{ 'Navigation.Seminar' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'seminars']">{{
                'Navigation.Seminar_SubMenu' | translate
              }}</a>
            </li>

            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/blog"
                target="_blank"
                rel="nofollow noopener"
                >{{ 'Sitemap.knowledge-accounting' | translate }}</a
              >
            </li>

            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'tutorials']">{{
                'Sitemap.Tutorial' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'ultimateguide']">{{
                'Navigation.Ultimate' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/faq"
                rel="nofollow noopener"
                target="_blank"
                >{{ 'Navigation.Faq' | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- MOBILE  -->
      <div class="sitemap-mobile">
        <div class="sitemap-mobile__column column--1">
          <div class="site-map__heading">
            {{ 'Navigation.Products' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
                >FlowAccount</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/mobile-pos"
                rel="nofollow noopener"
                target="_blank"
                >MobilePOS</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/payroll"
                rel="nofollow noopener"
                target="_blank"
                >Payroll</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-line white-color"
                href="https://flowaccount.com/{{ translate.currentLang }}/autokey"
                rel="nofollow noopener"
                target="_blank"
                >AutoKey</a
              >
            </li>
          </ul>
        </div>
        <div class="sitemap-mobile__column column--2">
          <div class="site-map__heading"> FlowAccount </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'functions', 'business']"
                rel="noopener"
                >{{ 'Sitemap.BusinessFunctions' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'functions', 'accounting']"
                rel="noopener"
                >{{ 'Sitemap.AccountingFunctions' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'functions', 'quotation']">
                {{ 'Sitemap.quotation' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'functions', 'receipt']">
                {{ 'Sitemap.receipt' | translate }}</a
              >
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'developers']">{{
                'Navigation.Developer' | translate
              }}</a>
            </li>
          </ul></div
        >
        <div class="sitemap-mobile__column column--3">
          <div class="site-map__heading">
            {{ 'Navigation.Seminar' | translate }}
          </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'seminars']">{{
                'Navigation.Seminar_SubMenu' | translate
              }}</a>
            </li>

            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/blog"
                target="_blank"
                rel="nofollow noopener"
                >{{ 'Sitemap.knowledge-accounting' | translate }}</a
              >
            </li>

            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'tutorials']">{{
                'Sitemap.Tutorial' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'ultimateguide']">{{
                'Navigation.Ultimate' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                href="https://flowaccount.com/faq"
                rel="nofollow noopener"
                target="_blank"
                >{{ 'Navigation.Faq' | translate }}</a
              >
            </li>
          </ul></div
        >

        <div class="sitemap-mobile__column column--4">
          <!-- FlowPayroll -->
          <div class="site-map__heading payroll"> Payroll </div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a class="white-line" href="https://flowpayroll.co/functions" rel="nofollow noopener" target="_blank">{{
                'Sitemap.BusinessFunctions' | translate
              }}</a>
            </li>
          </ul>

          <!-- Auto key -->
          <div class="site-map__heading autokey">AutoKey</div>
          <ul class="site-map-ul">
            <li class="sile-map-li"
              ><a
                class="white-line"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
              >
                {{ 'Sitemap.BusinessFunctions' | translate }}</a
              ></li
            >
            <li class="sile-map-li"
              ><a
                class="white-line"
                href="https://autokey.io/{{ language | async }}"
                rel="nofollow noopener"
                target="_blank"
              >
                {{ 'Sitemap.AccountingFunctions' | translate }}</a
              ></li
            >
          </ul>
        </div>
        <div class="sitemap-mobile__column column--5"
          ><div
            class="site-map__heading find-accounting"
            [routerLink]="[language | async, 'accountingfirm', 'businessowner']"
          >
            {{ 'Sitemap.find-accountant' | translate }}
          </div></div
        >
        <div class="sitemap-mobile__column column--6">
          <div
            class="site-map__heading vat-wht-calculation"
            [routerLink]="[language | async, 'functions', 'calculator-tax-and-profit']"
          >
            {{ 'Sitemap.vat-wht-calculation' | translate }}
          </div>
        </div>
        <div class="sitemap-mobile__column column--7">
          <div class="site-map__heading">{{ 'Navigation.About Us' | translate }}</div>
          <ul class="site-map-ul">
            <li class="sile-map-li">
              <a
                class="white-color white-line"
                [routerLink]="[language | async, 'accountingfirm', 'become-our-partner']"
              >
                {{ 'Sitemap.Become-partner' | translate }}
              </a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'about-us']">
                {{ 'Sitemap.About' | translate }}
              </a>
            </li>
            <li class="sile-map-li">
              <ng-container *ngIf="!hasContactUsPage">
                <a class="white-color white-line" href="javascript:void(0)" (click)="goToContact()">{{
                  'Navigation.Contact Us' | translate
                }}</a>
              </ng-container>

              <ng-container *ngIf="hasContactUsPage">
                <a class="white-color white-line" [routerLink]="[language | async, 'contact-us']">{{
                  'Navigation.Contact Us' | translate
                }}</a>
              </ng-container>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" [routerLink]="[language | async, 'jobs']">{{
                'Navigation.Jobs' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/term-of-use" target="_blank">{{
                'Navigation.termOfUse' | translate
              }}</a>
            </li>
            <li class="sile-map-li">
              <a class="white-color white-line" href="{{ language | async }}/privacy-statement" target="_blank">{{
                'Navigation.Privacy' | translate
              }}</a>
            </li>

            <li class="sile-map-li max-150">
              <a
                class="white-color white-line"
                href="{{ language | async }}/upgrade-to-new-flowaccount"
                target="_blank"
                [innerHTML]="'Navigation.Migration' | translate"
              ></a>
            </li> </ul
        ></div>
        <div class="sitemap-mobile__column column--8"></div>
      </div>
    </div>
  </section>
</ng-container>
