import { HttpClient, JsonpClientBackend } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from '@landing-src/environments/environment'
import { AccountingFirm } from '@landing-app/models/accountingFirm.model'
import { AccountingFirmDisplayType } from '@landing-app/models/box-format.model'
import { expand, take, toArray } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class SquidexService {
  constructor(private readonly httpClient: HttpClient) {}

  // global service ไว้เรียกจาก squidex ไม่จำเป็นต้องสร้างใหม่ทุกครั้ง
  public getContentSquidexApi(pathName: string): Observable<any> {
    const query = {
      $orderby: 'created asc',
    }
    const url = this.getUrl(pathName, query)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }
  public getContentSquidexApiOrder(pathName: string): Observable<any> {
    const query = {
      $orderby: 'data/priority/iv asc',
    }
    const url = this.getUrl(pathName, query)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }
  public getContentSquidexApiList(pathName: string): Observable<any> {
    const url = this.getUrl(pathName)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  public getAccountingFirms(displayType: AccountingFirmDisplayType): Observable<any[]> {
    const query = {
      $orderby: 'data/priority/iv asc',
      $filter: 'data/isRecommend/iv eq ' + (displayType === 'recommend'),
    }
    const url = this.getUrl('accountingfirm', query)

    return this.httpClient.get<any>(url).pipe(
      expand((res) => {
        if (res.items.length < res.total) {
          const nextPage = Math.ceil(res.items.length / 200) + 1
          const nextPageUrl = this.getUrl('accountingfirm', { ...query, $skip: nextPage * 200 })
          return this.httpClient.get<any>(nextPageUrl)
        } else {
          return of()
        }
      }),
      take(3), // the request #3 is for the second page of squidex data
      toArray(),
      map((responses) => {
        const items = responses.flatMap((res) => res.items)
        return items.map((x: any) => parseAccountingFirm(x))
      }),
    )
  }

  // START: FlowAcademy(Seminar) Page
  // https://cloud.squidex.ioapi/content/flowaccount-crm/flowacademy-exclusive-course/

  public getSeminarExclusiveCourseAPI(): Observable<any> {
    const coursePath = 'flowacademy-exclusive-course'
    const url = this.getUrl(coursePath)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  getFlowAcademyCoursesAPI(path: string): Observable<any> {
    const query = {
      $orderby: 'data/CourseDatePicker/iv asc',
    }
    const url = this.getUrl(path, query)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  public getSeminarCourseShowHomePageAPI(coursePath: string): Observable<any> {
    const query = {
      $filter: 'data/isShowOnHomePage/iv eq ' + "'Yes'",
    }
    const url = this.getUrl(coursePath, query)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  public getAccountingFirmPartnerTestimonialAPI(): Observable<any> {
    const url = this.getUrl('accounting-firm-swiper')
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  public getByIdsAPI(ids: any): Observable<any> {
    let path = '?ids='
    ids.forEach((element, index) => {
      path = path + element + (index === ids.length - 1 ? '' : ',')
    })
    const url = this.getUrl(path)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  public getByJoinIdsAPI(ids: string): Observable<any> {
    let path = '?ids=' + ids
    const url = this.getUrl(path)
    return this.httpClient.get<any>(url).pipe(map((res) => res.items))
  }

  // insert data by post
  public insertAccountingFirms(data, isRecommend: boolean): Observable<any> {
    const input = parseAccountingFirmJson(data, isRecommend)
    const url = this.getUrl('accountingfirm')
    return this.httpClient.post(url, input)
  }

  private getUrl(path, query = null): string {
    let queryString = ''
    if (query !== null) {
      queryString = '?'
      let isFirst = true
      for (const [key, element] of Object.entries(query)) {
        if (isFirst) {
          isFirst = false
        } else {
          queryString = queryString + '&'
        }
        queryString = queryString + key + '=' + element
      }
    }
    return environment.squidexAuth.url + '/api/content/' + environment.squidexAuth.appName + '/' + path + queryString
  }
}

function parseAccountingFirm(response: any): AccountingFirm {
  return {
    id: response.data.id.iv,
    name_th: response.data.name.th,
    name_en: response.data.name.en,
    type: response.data.type.iv,
    province_th: response.data.province.th,
    province_en: response.data.province.en,
    region: response.data.region.iv,
    logo: response.data.logo.iv,
    priority: response.data.priority.iv,
    isRecommend: response.data.isRecommend.iv,
    nameUrl: response.data.nameUrl ? response.data.nameUrl.iv : '',
  }
}

// แปลงข้อมูลเพื่อใส่ใน post ไป create data ที่ squidex
function parseAccountingFirmJson(data: any, isRecommend: boolean) {
  return {
    id: {
      iv: data.id,
    },
    name: {
      th: data.name_th,
      en: data.name_en,
    },
    type: {
      iv: data.type,
    },
    province: {
      th: data.province_th,
      en: data.province_en,
    },
    region: {
      iv: data.region,
    },
    logo: {
      iv: data.logo ? data.logo : '',
    },
    priority: {
      iv: data.priority ? data.priority : -1,
    },
    isRecommend: {
      iv: isRecommend,
    },
    nameUrl: {
      iv: data.nameUrl,
    },
  }
}
