export interface UserAnalyticCollector<T> {
  id: string
  utmId: string
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmTerm: string
  utmContent: string
  userTrafficSource: string
  userType: string
  userAgent: string
  userOS: string
  userOsVersion: string
  userBrowser: string
  userDeviceType: string
  userOrientationType: string
  userLanguage: string
  userCountry: string
  userTimezone: string
  userCity: string
  userRegion: string
  screenWidth: number
  screenHeight: number
  userResolution: string
  userSubscriptionStatus: string
  userVisitDate: string
  userVisitTime: string
  userDayOfWeek: string
  previousUrl: string
  currentUrl: string
  referralUrl: string
  eventId: UserAnalyticEvents
  createdAt: Date
  payLoad: T
}

export enum UserAnalyticEvents {
  DemoSchedule = 101,
  PageView = 102,
  PackageSelection = 103,
  PeriodTypeToggle = 104,
  Signup = 105,
  Login = 106,
  Logo = 107,
}

export interface UserAnalyticDataEvent {
  dataId: string
  dataName: string
}
