import { Component } from '@angular/core'
import { IAppState } from '@flowaccount/landing'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

@Component({
  selector: 'flowaccount-sitemap',
  templateUrl: './sitemap.component.html',
  // styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent {
  public language: Observable<string>

  public isOnFooter2021 = true

  /* hasContactUsPage  */
  /* ส่วนนี้มีความสำคัญตรง footer จึง if = false ไว้ เผื่อเหตุฉุกเฉแินได้กลับมาเปิดใช้ได้  */
  public hasContactUsPage = true

  constructor(public translate: TranslateService, protected store: Store<{ appState: IAppState }>) {
    this.language = store.select(s => s.appState.langPath)
  }

  goToContact() {
    const target = document.getElementById('contactus')
    target.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }
}
